import { createSelector } from 'reselect';

export const FEATURES_MAP = {
  MULTI_VEHICLE_OPTIMISATION: 'multi_vehicle_optimisation',
  WORKER_SEQUENCE_MANAGEMENT: 'worker_sequence_rearrangement',
  BROADCAST: 'broadcast',
  ALLOW_UPSTREAM_EDIT_TRANSFERRED_ITEMS: 'allow_upstream_edit_transferred_items',
  ORDER_BULK_EDIT: 'order_bulk_edit',
  MANUAL_PLANNING: 'manual_planning',
  TIMELINE: 'timeline',
  USE_BATCH_ID: 'use_batch_id',
  USING_CONNOTE: 'using_connote',
  VEHICLES: 'vehicles',
  QUICK_ASSIGN_ALLOW_REASSIGN: 'quick_assign_allow_reassign',
  ENABLE_DRIVER_COMMISSION: 'enable_driver_commission',
  ADDRESS_BASED_QUICK_ASSIGN: 'address_based_quick_assign',
  TASK_ASSIGNMENT_WITH_VEHICLE: 'task_assignment_with_vehicle',
  MULTILEG: 'multileg',
  SINGLE_TASK_BATCH_UPLOAD: 'single_task_batch_upload',
  ALLOW_CANCELLATION_WITH_COMPLETED_TASKS: 'allow_cancellation_with_completed_tasks',
  ALLOW_CANCELLATION_WITH_ASSIGNED_TASKS: 'allow_cancellation_with_assigned_tasks',
  WAREHOUSE_SCANNER: 'warehouse_scanner',
  AGGREGATED_STATS: 'aggregated_stats',
  USE_PROFORMA_INVOICE: 'use_proforma_invoice',
  USING_CONTAINER: 'using_container',
  SERVICE_TIMES_MANAGEMENT: 'service_times_management',
  ALLOW_SHOW_TASK_TYPE_IN_TASK_EXCEPTION: 'allow_show_task_type_in_task_exception',
  ENTERPRISE_USER_MANAGEMENT: 'enterprise_user_management',
  USING_MANIFEST: 'using_manifest',
  ADDRESS_BOOK: 'address_book',
  ITEM_TYPES_MANAGEMENT_VIEW: 'item_types_management.view',
  ITEM_TYPES_MANAGEMENT_CHANGE: 'item_types_management.change',
  SERVICE_TYPES_MANAGEMENT: 'service_types_management',
  OPERATIONS: 'operations',
  SAVED_FILTERS: 'saved_filters',
  DISPATCH_RULES: 'dispatch_rules',
  WHITELABEL: 'whitelabel',
  SHOW_RATING_MANAGEMENT: 'show_rating_management',
  VAS_MANAGEMENT: 'vas_management',
  TRUCK_MANAGEMENT: 'truck_management',
  DISPLAY_BILLING_PARTY: 'display_billing_party',
  ORDER_FIELD_TEMPLATE: 'order_field_template',
  DOCUMENT_PORTAL: 'document_portal',
  USE_SENDER_NAME_IN_MISSING_INFO_FIX: 'use_sender_name_in_missing_info_fix',
  ENABLE_PARTNERS: 'enable_partners',
  PARTNER_INVITATION: 'partner_invitation',
  USE_RATE_ENGINE: 'use_rate_engine',
  LIVE_TRACKING_MAP: 'live_tracking_map',
  VEHICLE_SCHEDULES: 'vehicle_schedules',
  WORKER_SCHEDULES: 'worker_schedules',
  CONSOLIDATION: 'consolidation',
  APPLY_HUB_OPERATIONS: 'apply_hub_operations',
  USING_CUSTOM_FIELDS: 'using_custom_fields',
  USING_SENDER_PROFILES: 'using_sender_profiles',
  ALLOW_EDIT_EMAIL_NOTIFICATION: 'allow_edit_email_notification',
  ALLOW_SELECT_PICKUP_PERSON_EMAIL_NOTIFICATION: 'allow_select_pickup_person_email_notification',
  ALLOW_SELECT_DROPOFF_PERSON_EMAIL_NOTIFICATION: 'allow_select_dropoff_person_email_notification',
  USING_LANGUAGE_SELECTGOR: 'using_language_selector',
  INTEGRATIONS_MANAGEMENT: 'integrations_management',
  ALLOW_NOTIFICATIONS: 'allow_notifications',
  ENABLED_REPORTS_LIST_PAGE: 'enabled_reports_list_page',
  DASHBOARD: 'dashboard',
  BATCH_ASSIGNMENT: 'batch_assignment',
};

export const getFeatureOverride = (feature) => {
  const featureOverrides = localStorage.getItem('feature_overrides');

  if (!featureOverrides) {
    return null;
  }

  try {
    const features = JSON.parse(featureOverrides);
    return features[feature];
  } catch (error) {
    console.error('Failed to parse feature_overrides from local storage', error);
    return null;
  }
};

export const isFeatureEnabled = ({ companyFeatures, featureToggles }, feature, defaultValue) => {
  return getFeatureOverride(feature) ?? companyFeatures?.[feature] ?? featureToggles?.[feature] ?? defaultValue;
};

export const isFeatureEnabledSelector = createSelector(
  [(state) => state && state.features, (_, feature) => feature, (_1, _2, defaultValue) => defaultValue],
  (features, feature, defaultValue) => isFeatureEnabled(features, feature, defaultValue)
);
